import { useEffect, useState } from "react";

import { format } from "date-fns";
import { useToast } from "hooks/toast";
import { useTheme } from "styled-components";
import { FiChevronLeft } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { ContentBox } from "components/ContentBox";
import { LoadingProfiz } from "components/LoadingProfiz";
import { ButtonSVG } from "../../../components/ButtonSVG";
import { ModalRight } from "components/ModalRight";
import { CardChecklistDetail } from "components/CardChecklistDetail";

import { ClientProps, UnityDTO } from "dtos/ClientDTO";
import { ServiceOrderProps } from "dtos/ServiceOrderDTO";
import { LocalChecklistProps, MaterialProps } from "dtos/ServiceOrderDetailDTO";
import { ServiceBudgetProps } from "dtos/BudgetDetailsDTO";
import { ModeProps } from "dtos/businessProposalsDTO";
import { RegisterProps } from "dtos/ServiceChecklistDTO";

import addMaskMoney from "utils/addMaskMoney";
import {
  mappedBudgetProductToUseInApp,
  mappedBudgetProducts,
} from "utils/mappedBudgetProducts";
import {
  ProductPropsUsedInContext,
  useSelectedProducts,
} from "hooks/budget/selectedProducts";

import axios from "axios";
import { api } from "services/api";
import apiv2 from "services/apiv2";

import { mappedServicesForApi } from "utils/mappedServicesForApi";
import { mappedLocalChecklistForApi } from "utils/mappedLocalChecklistForApi";
import { mappedMaterialsForApi } from "utils/mappedMaterialsForApi";
import { useSelectedService } from "hooks/budget/selectedServices";
import { useSelectedChecklist } from "hooks/budget/selectedLocalChecklist";
import { useSelectedMaterial } from "hooks/budget/selectedMaterials";
import { useSelectedPaymentConditions } from "hooks/budget/selectedPaymentConditions";

import { mappedServicesFromApi } from "utils/mappedServicesFromApi";
import { mappedLocalChecklistFromApi } from "utils/mappedLocalChecklistFromApi";
import { mappedMaterialsFromApi } from "utils/mappedMaterialsFromApi";
import { useMaterialsForEditingBudget } from "hooks/budget/materialsForEditingBudget";

import * as S from "./styles";
import { RenderLocationAddress } from "components/RedirectLocationAddress";
import { ModalObservationDetail } from "components/ModalObservationDetail";

type RouteParams = {
  id: string;
};

type UnitiesResponse = {
  unities: UnityDTO[];
};

type CardChecklistDetailProps = {
  nameCheckList: string;
  checklistRegisters: RegisterProps[];
};

export function ApprovedBudget() {
  const theme = useTheme();
  const history = useHistory();

  const {
    selectedProductsInContext,
    handleSetSelectedProducts,
    clearSelectedProducts,
  } = useSelectedProducts();

  const { selectedServices, handleSetSelectedServices, clearServicesData } =
    useSelectedService();

  const {
    selectedChecklistItems,
    handleSetSelectedChecklist,
    clearChecklistsData,
  } = useSelectedChecklist();

  const { selectedMaterials, handleSetSelectedMaterials, clearMaterialsData } =
    useSelectedMaterial();

  const { handleSetMaterialsForEditingBudget } = useMaterialsForEditingBudget();

  const {
    selectedPromptPayment,
    selectedInstallmentPayment,
    selectedIncomingPayment,
    handleSetSelectedPromptPayment,
    handleSetSelectedInstallmentPayment,
    handleSetSelectedIncomingPayment,
  } = useSelectedPaymentConditions();

  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();

  const [idSequence, setIdSequence] = useState(0);
  const [pdfLink, setPdfLink] = useState("");
  const [clientInBudget, setClientInBudget] = useState({} as ClientProps);
  const [unityInBudget, setUnityInBudget] = useState({} as UnityDTO);
  const [mode, setMode] = useState<ModeProps | "">("");

  const [productsInBudget, setProductsInBudget] = useState<
    ProductPropsUsedInContext[]
  >([]);

  const [servicesInBudget, setServicesInBudget] = useState<
    ServiceBudgetProps[]
  >([]);

  const [checklistInBudget, setChecklistInBudget] = useState<
    LocalChecklistProps[]
  >([]);

  const [materialsInBudget, setMaterialsInBudget] = useState<MaterialProps[]>(
    []
  );

  const [totalBudget, setTotalBudget] = useState(0);
  const [observationInBudget, setObservationInBudget] = useState("");
  const [formattedCreationDate, setFormattedCreationDate] = useState("");
  const [loading, setLoading] = useState(true);

  const [servicesAmount, setServicesAmount] = useState(0);
  const [materialsAmount, setMaterialsAmount] = useState(0);
  const [checklistsAmount, setChecklistsAmount] = useState(0);

  const [addressNotExists, setAddressNotExists] = useState(false);

  const [loadingButtonModel, setLoadingButtonModel] = useState(false);

  const [budgetValidation, setBudgetValidation] = useState("");
  const [isOpenObservationDetail, setIsOpenObservationDetail] = useState("");
  const [isOpenCheckListDetail, setIsOpenCheckListDetail] =
    useState<CardChecklistDetailProps>({} as CardChecklistDetailProps);

  async function loadUnityData(clientResponse: ClientProps) {
    try {
      if (clientResponse.address?.id?.toString() === "0") {
        return setAddressNotExists(true);
      }

      if (clientResponse?.address && clientResponse?.address?.id) {
        clientResponse.address &&
          setUnityInBudget(clientResponse?.address as UnityDTO);
        return;
      }

      const response = await api.get<UnitiesResponse>(
        `/clients/${clientResponse.id}/unities`,
        {
          params: {
            limit: 50,
            offset: 0,
            client_id: clientResponse.id,
          },
        }
      );

      const defaultUnity = response.data.unities.find((unity) => unity.default);

      if (response.data.unities.length < 1 || !defaultUnity) {
        setAddressNotExists(true);
        return;
      }

      defaultUnity && setUnityInBudget(defaultUnity);
    } catch {
      addToast({
        title: "Opss",
        description:
          "Ocorreu um erro ao carregar o endereço do cliente, tente novamente.",
        type: "error",
      });
    }
  }

  function clearAllDataBudget() {
    clearSelectedProducts();
    clearServicesData();
    clearChecklistsData();
    clearMaterialsData();
  }

  useEffect(() => {
    async function getBudgetDetails() {
      try {
        const { data } = await apiv2.get(`/budgets/${id}`);

        const {
          mode,
          sequenceNumber,
          detailLink,
          created_at,
          client,
          products,
          services,
          localChecklists,
          materials,
          observations,
          total,
          payment_condition,
          budget_validity,
        } = data;

        setIdSequence(sequenceNumber);
        setMode(mode);

        const formattedDate = format(new Date(created_at), "dd/MM/yyyy");
        setFormattedCreationDate(formattedDate);
        setPdfLink(detailLink);
        setTotalBudget(total);
        setClientInBudget(client);
        await loadUnityData(client);
        setProductsInBudget(mappedBudgetProductToUseInApp(products));
        setServicesInBudget(services);
        setChecklistInBudget(localChecklists);
        setMaterialsInBudget(materials);
        setObservationInBudget(observations);

        const mappedProducts = mappedBudgetProductToUseInApp(products);
        handleSetSelectedProducts(mappedProducts);

        const mappedServices = mappedServicesFromApi(services);
        handleSetSelectedServices(mappedServices);

        const mappedLocalChecklists =
          mappedLocalChecklistFromApi(localChecklists);
        handleSetSelectedChecklist(mappedLocalChecklists);

        const mappedMaterials = mappedMaterialsFromApi(materials);
        handleSetSelectedMaterials(mappedMaterials);
        handleSetMaterialsForEditingBudget(mappedMaterials);

        handleSetSelectedPromptPayment(payment_condition.promptPayment, total);
        handleSetSelectedInstallmentPayment(
          payment_condition.installment,
          total
        );
        handleSetSelectedIncomingPayment(payment_condition.incoming, total);
        setBudgetValidation(budget_validity);
      } catch (error) {
        addToast({
          type: "error",
          title: "Ops...",
          description: "Ocorreu um erro ao carregar os dados do orçamento.",
        });
      } finally {
        setLoading(false);
      }
    }

    getBudgetDetails();

    return () => clearAllDataBudget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, id]);

  function handleGoBack() {
    history.goBack();
  }

  async function handleSaveBudgetAsModel() {
    try {
      setLoadingButtonModel(true);
      const budgetData = {
        client: {},
        products: mappedBudgetProducts(selectedProductsInContext),
        services: mappedServicesForApi(selectedServices),
        localChecklists: mappedLocalChecklistForApi(selectedChecklistItems),
        materials: mappedMaterialsForApi(selectedMaterials),
        budget_validity: budgetValidation,
        total: totalBudget,
        payment_condition: {
          promptPayment: selectedPromptPayment,
          installment: selectedInstallmentPayment,
          incoming: selectedIncomingPayment,
        },
        observations: observationInBudget,
        originCreate: "web",
        mode,
      };

      await apiv2.post(`budgets/${id}/model`, budgetData);

      addToast({
        title: "Certo!",
        description: "Este orçamento foi salvo como modelo.",
        type: "success",
      });
    } catch (error) {
      addToast({
        title: "Ops!!",
        description:
          axios.isAxiosError(error) && error.response?.data.error
            ? error.response.data.error
            : "Ocorreu um erro ao salvar o orçamento como modelo.",
        type: "error",
      });
    } finally {
      setLoadingButtonModel(false);
    }
  }

  function handleOpenPdfPage() {
    history.push({
      pathname: "/download/pdf",
      state: {
        pdfDownloadLink: pdfLink,
      },
    });
  }

  async function handleNavigateToServiceOrder() {
    try {
      setLoading(true);
      const response = await apiv2.get("/budgets/service-orders", {
        params: {
          offset: 0,
          limit: 1,
          budgetID: id,
        },
      });

      const serviceOrder: ServiceOrderProps = response.data.ordens[0];

      const hasScheduled = serviceOrder!.services.some(
        (service) => !!service.scheduledDate
      );

      if (serviceOrder?.status === "concluded") {
        history.push(`/service-order/concluded/${serviceOrder!.id}`);
        return;
      }

      if (hasScheduled) {
        history.push(`/service-order/scheduled/${serviceOrder!.id}`);
        return;
      }

      history.push(`/service-order/pending/${serviceOrder!.id}`);
    } catch (error) {
      addToast({
        type: "error",
        title: "Ops...",
        description: "Ocorreu um erro ao carregar os dados do orçamento.",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleToggleModalObservationDetail(observationDetail: string) {
    setIsOpenObservationDetail(observationDetail);
  }

  function handleToggleModalCheckListDetail({
    nameCheckList,
    checklistRegisters,
  }: CardChecklistDetailProps) {
    setIsOpenCheckListDetail({ nameCheckList, checklistRegisters });
  }

  useEffect(() => {
    const serviceSum = servicesInBudget
      .map((service) => service.quantity * service.service.price)
      .reduce((accumulated, current) => accumulated + current, 0);

    const materialSum = materialsInBudget
      .map((material) => material.material.price * material.quantity)
      .reduce((accumulated, current) => accumulated + current, 0);

    const checklistSum = checklistInBudget
      .map((item) => Number(item.checklist.value) * item.quantity)
      .reduce((accumulated, current) => accumulated + current, 0);

    setServicesAmount(serviceSum);
    setMaterialsAmount(materialSum);
    setChecklistsAmount(checklistSum);
  }, [servicesInBudget, materialsInBudget, checklistInBudget]);

  return (
    <S.Container>
      <ModalRight
        isOpen={!!isOpenObservationDetail}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() => handleToggleModalObservationDetail("")}
      >
        <ModalObservationDetail
          observation={isOpenObservationDetail}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() => handleToggleModalObservationDetail("")}
        />
      </ModalRight>

      <ModalRight
        isOpen={!!isOpenCheckListDetail?.nameCheckList}
        // eslint-disable-next-line react/jsx-no-bind
        handleToggleOpen={() =>
          handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
        }
      >
        <CardChecklistDetail
          nameCheckList={isOpenCheckListDetail?.nameCheckList}
          checklistRegisters={isOpenCheckListDetail?.checklistRegisters}
          // eslint-disable-next-line react/jsx-no-bind
          handleGoBack={() =>
            handleToggleModalCheckListDetail({} as CardChecklistDetailProps)
          }
        />
      </ModalRight>
      {loading ? (
        <LoadingProfiz isVisible={loading} />
      ) : (
        <>
          <S.Top>
            <S.ButtonClose onClick={() => handleGoBack()}>
              <FiChevronLeft size={10} color={theme.colors.title} />
            </S.ButtonClose>

            <h4>Orçamento</h4>
          </S.Top>

          <S.WrapperDate>
            <span>
              <p>{idSequence}</p>
            </span>

            <div>
              <span>
                Data de criação: <p>{formattedCreationDate}</p>
              </span>
            </div>

            <div>
              <div />
              <span>Aprovado</span>
            </div>
          </S.WrapperDate>

          <S.Content>
            <div>
              <span>Cliente:</span>

              <ContentBox>
                <S.ContentBoxTitle>{clientInBudget.name}</S.ContentBoxTitle>
                <S.ContentBoxText>{clientInBudget!.cpf_cnpj}</S.ContentBoxText>
                <S.ContentBoxText>{clientInBudget!.phone}</S.ContentBoxText>
                <S.ContentBoxText>{clientInBudget!.email}</S.ContentBoxText>
              </ContentBox>
            </div>

            <div>
              <span>Endereço:</span>
              <ContentBox>
                {addressNotExists ? (
                  <S.ContentBoxText>Sem endereço cadastrado.</S.ContentBoxText>
                ) : (
                  <>
                    <S.ContentBoxTitle>
                      {unityInBudget.name}{" "}
                      {unityInBudget.default ? "(principal)" : "(adicional)"}
                    </S.ContentBoxTitle>

                    <RenderLocationAddress
                      city={unityInBudget?.city}
                      complement={unityInBudget?.complement}
                      district={unityInBudget?.district}
                      number={Number(unityInBudget?.number)}
                      postalCode={unityInBudget?.postalCode}
                      street={unityInBudget?.street}
                      uf={unityInBudget?.uf}
                    />
                  </>
                )}
              </ContentBox>
            </div>

            {productsInBudget.length > 0 && (
              <div>
                <span>Produto(s): </span>

                {productsInBudget.map((product, index) => (
                  <div key={product.id}>
                    <ContentBox>
                      <S.ContentBoxText>
                        {product.name} ({product.quantity})
                      </S.ContentBoxText>

                      {product.brand && <span>Marca: {product.brand}</span>}

                      {product.warranty.time && (
                        <span>
                          &ensp;| Garantia: {product.warranty.time}{" "}
                          {product.warranty.extension}
                        </span>
                      )}

                      {product.observations && (
                        <S.ContentBoxText>
                          Detalhamento do produto: {product.observations}
                        </S.ContentBoxText>
                      )}

                      <S.WrapperTotal>
                        <S.ContentBoxText>
                          Valor:
                          <S.TextBold>
                            &ensp;{product.formattedTotal}
                          </S.TextBold>
                        </S.ContentBoxText>
                      </S.WrapperTotal>
                    </ContentBox>
                    {index !== productsInBudget.length - 1 && <S.Separator />}
                  </div>
                ))}
              </div>
            )}

            {servicesInBudget.length > 0 && (
              <div>
                <span>Serviço(s):</span>

                {servicesInBudget.map((service, index) => {
                  const {
                    service: serviceData,
                    quantity,
                    idBudgetService,
                  } = service;

                  return (
                    <div key={idBudgetService | index}>
                      <ContentBox>
                        <S.ContentBoxText>
                          {serviceData.service.name} ({quantity})
                        </S.ContentBoxText>

                        {serviceData.equipment.name ? (
                          <span>
                            {serviceData.equipment.name}{" "}
                            {serviceData.equipmentType.name}
                          </span>
                        ) : (
                          <span>Serviço sem equipamento</span>
                        )}

                        <span> {serviceData.capacity.name}</span>
                        {serviceData.brand && (
                          <span>&ensp;| Marca: {serviceData?.brand}</span>
                        )}

                        {serviceData.runtime.time &&
                          serviceData.warranty.time && (
                            <S.ContentBoxText>
                              Execução: {serviceData.runtime.time}{" "}
                              {serviceData.runtime.extension}
                              &ensp;|&ensp;Garantia: {
                                serviceData.warranty.time
                              }{" "}
                              {serviceData.warranty.extension}
                            </S.ContentBoxText>
                          )}

                        {serviceData?.description && (
                          <>
                            <br />
                            <S.ObservationButton
                              type="button"
                              onClick={() =>
                                handleToggleModalObservationDetail(
                                  serviceData.description
                                )
                              }
                            >
                              Detalhamento do Serviço
                            </S.ObservationButton>
                          </>
                        )}

                        <br />
                        <S.ObservationButton
                          type="button"
                          onClick={() =>
                            handleToggleModalCheckListDetail({
                              nameCheckList:
                                serviceData?.serviceChecklist![0]?.name,
                              checklistRegisters:
                                serviceData?.serviceChecklist![0]?.registers,
                            })
                          }
                        >
                          Detalhamento dos Registros
                        </S.ObservationButton>

                        <S.WrapperTotal>
                          <S.ContentBoxText>
                            Valor:
                            <S.TextBold>
                              &ensp;{addMaskMoney(serviceData.price * quantity)}
                            </S.TextBold>
                          </S.ContentBoxText>
                        </S.WrapperTotal>
                      </ContentBox>
                      {index !== servicesInBudget.length - 1 && <S.Separator />}
                    </div>
                  );
                })}
              </div>
            )}

            {checklistInBudget.length > 0 && (
              <div>
                <span>Itens adicionais:</span>
                {checklistInBudget.map((checklist) => {
                  const {
                    checklist: checklistData,
                    quantity,
                    type,
                  } = checklist;

                  const status = checklistData?.status.find(
                    (sts) => sts.type === type
                  );

                  return (
                    <ContentBox key={checklistData.id}>
                      <div>
                        <S.ContentBoxText>
                          <span style={{ color: theme.colors.title }}>
                            {checklistData.name}:
                          </span>{" "}
                          &nbsp;
                          {status?.label} &nbsp;
                          {type === "my_responsibility" && (
                            <span>({quantity})</span>
                          )}
                        </S.ContentBoxText>
                        <S.ContentBoxText>
                          <span style={{ color: theme.colors.title }}>
                            Valor: &ensp;
                          </span>

                          <S.TextBold>
                            {addMaskMoney(
                              Number(checklistData.value) * quantity
                            )}
                          </S.TextBold>
                        </S.ContentBoxText>
                      </div>
                    </ContentBox>
                  );
                })}
              </div>
            )}

            {materialsInBudget.length > 0 && (
              <div>
                <span>Materiais:</span>
                {materialsInBudget.map((material, index) => {
                  const { material: materialData, quantity } = material;

                  return (
                    <div key={materialData.id}>
                      <ContentBox>
                        <div>
                          <S.ContentBoxText>
                            {materialData.item.name} &nbsp; ({quantity})
                          </S.ContentBoxText>
                          <S.ContentBoxText>
                            <span style={{ color: theme.colors.text }}>
                              Tipo: &ensp;
                            </span>
                            {materialData.type.name} &nbsp;
                          </S.ContentBoxText>

                          <S.ContentBoxText>
                            Valor:
                            <S.TextBold>
                              &ensp;
                              {addMaskMoney(materialData.price * quantity)}
                            </S.TextBold>
                          </S.ContentBoxText>
                        </div>
                      </ContentBox>
                      {index !== servicesInBudget.length - 1 && <S.Separator />}
                    </div>
                  );
                })}
              </div>
            )}

            {observationInBudget && (
              <div>
                <span>Observações:</span>

                <ContentBox>
                  <S.ContentBoxObservationText>
                    {observationInBudget}
                  </S.ContentBoxObservationText>
                </ContentBox>
              </div>
            )}

            <div>
              <span>Totais:</span>
              <ContentBox>
                <S.ContentBoxText>
                  Serviços: &ensp;
                  <S.TextBold>{addMaskMoney(servicesAmount)}</S.TextBold>
                </S.ContentBoxText>
                <S.ContentBoxText>
                  Materiais: &ensp;
                  <S.TextBold>{addMaskMoney(materialsAmount)}</S.TextBold>
                </S.ContentBoxText>
                <S.ContentBoxText>
                  Itens adicionais: &ensp;
                  <S.TextBold>{addMaskMoney(checklistsAmount)}</S.TextBold>
                </S.ContentBoxText>
                <S.ContentBoxText>
                  Total do orçamento: &ensp;
                  <S.TextBold className="moneyText">
                    {addMaskMoney(totalBudget)}
                  </S.TextBold>
                </S.ContentBoxText>
              </ContentBox>
            </div>

            {mode !== "products" && (
              <div>
                <S.WrapperTotal>
                  <Button
                    typeButton="outline"
                    onClick={handleNavigateToServiceOrder}
                    loading={loading}
                  >
                    Acessar ordem de serviço nº{idSequence}
                  </Button>
                </S.WrapperTotal>
              </div>
            )}

            <S.WrapperButtonsSVG>
              {/* <ButtonSVG title="Enviar para o cliente" typeSvg="client" /> */}
              <ButtonSVG
                title="Salvar como modelo"
                loading={loadingButtonModel}
                typeSvg="model"
                onClick={() => handleSaveBudgetAsModel()}
              />

              <ButtonSVG
                title="Download PDF"
                typeSvg="pdf"
                onClick={handleOpenPdfPage}
              />
            </S.WrapperButtonsSVG>
          </S.Content>
        </>
      )}
    </S.Container>
  );
}
